var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"register"}},[_c('stm-promise-context',{attrs:{"promise":_vm.submitPromise},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var submitContext = ref.promiseContext;
return [(submitContext.error && !submitContext.isPending)?_c('stm-message-box',{attrs:{"message-type":"error","message":submitContext.error.message}}):_vm._e(),_c('h2',[_vm._v("Créer mon compte")]),_c('stm-captcha',{attrs:{"public-key":_vm.captchaKey},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var captchaApi = ref.captchaApi;
return [_c('stm-form',{ref:"form",attrs:{"disable-button":submitContext.isPending},on:{"onSubmit":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.onSubmit.apply(void 0, argsArray.concat( [captchaApi] ))}},scopedSlots:_vm._u([{key:"txtBtnSubmit",fn:function(){return [_vm._v(" Créer mon compte ")]},proxy:true}],null,true)},[_c('stm-input-text',{attrs:{"name":"courriel","autocomplete":"on","rules":"required|email","type":"email","maxlength":"254","label":"Courriel"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('stm-input-password',{model:{value:(_vm.form.passwordConfirmation),callback:function ($$v) {_vm.$set(_vm.form, "passwordConfirmation", $$v)},expression:"form.passwordConfirmation"}})],1)]}}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }