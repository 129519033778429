import { render, staticRenderFns } from "./form.vue?vue&type=template&id=d687b4f2&"
import script from "./form.ts?vue&type=script&lang=ts&"
export * from "./form.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports